import gif from '../img/movieSpotgif.gif'
export const poster_url = 'https://www.themoviedb.org/t/p/w300_and_h450_bestv2'
export const poster_url_desktop = 'https://media.themoviedb.org/t/p/w1066_and_h600_bestv2'
export const moviespot_gif = {gif}
export const profile_url  = 'https://media.themoviedb.org/t/p/w276_and_h350_face'

{/* <img src={  gif } className='rounded-2xl p-[10px] ml-3  cursor-pointer  border-none outline-none hover:scale-105 h-[131px] lg:w-[150px] lg:h-[200px] ' alt={item.title}></img> */}

export const tamilmovies = [
  {
    id: 781732,
    title: "Animal-2023",
    title1: "Animal",
    overview:
      "The son of a wealthy, powerful industrialist returns to India and undergoes a remarkable transformation as he becomes consumed by a quest for vengeance against those threatening his father's life.",
    poster_path: 'https://www.themoviedb.org/t/p/original/hr9rjR3J0xBBKmlJ4n3gHId9ccx.jpg',
    background_path: "https://www.themoviedb.org/t/p/original/pwBtk8ngoMtqn0qv8j1feSFTKUn.jpg",
    download_link: "https://tamilvip.app/link/?jjb4lao2shk3",
    realesdata: "2023"
  },
  {
    id: 1208217,
    title: "Joe-2023",
    title1: "Joe",
    overview:
      "The Premam-meets-Raja Rani plot revolves around Joe (Rio Raj, solid, though his line delivery sometimes feels artificial), who falls in love with his classmate Sujithra (Malavika Manoj, okayish) in college. Even though the Malayali girl reciprocates, the romance is doomed.",
    poster_path: "https://www.themoviedb.org/t/p/original/yRMJ4Jvr5YUXOQlYwxX4Q6H6P71.jpg",
    background_path: "https://www.themoviedb.org/t/p/original/jGMNc2MqJ2mPdc4ceFvx1JvVKV5.jpg",
    download_link: "https://drive.google.com/file/d/11A_TEDjo9cnR_gN263yP_oK0gmFf8CHU/view?usp=drivesdk",
    realesdata: "2023"
  },
  {
    id: 962074,
    title: "Captain-Miller-2024",
    title1: "Captain-Miller",
    overview:
      "A renegade Captain and his unconventional outlaws execute daring heists in the 1930s and 1940s. Miller must decide whether to continue running or confront the challenges head-on.",
    poster_path: "https://www.themoviedb.org/t/p/original/gq5OlT5ImxsNpkirpgf4rYngd3M.jpg",
    background_path: "https://www.themoviedb.org/t/p/original/u3lWiLi0e0W3aU822QdcxQIGBES.jpg",
    download_link: "https://drive.google.com/file/d/1-WJ5aRgv30NinWCYOj8EQmMEC-ahTjjD/view?usp=drivesdk",
    realesdata: "2024"
  },
  {
    id: 755388,
    title: "Ayalaan-2024",
    title1: "Ayalaan",
    overview:
      "A lost alien seeks help from four friends to get back to his home planet, while a group of hostile scientists tries to capture it.",
    poster_path: "https://www.themoviedb.org/t/p/original/8nryykQqfU7yqTz4FD7uEBjseZp.jpg",
    background_path: "https://www.themoviedb.org/t/p/original/edF35dA59ltU0dZdCJ66M9TBrp5.jpg",
    download_link: "https://drive.google.com/file/d/1198ztKFCOFbwFF4ZV__kr4YSl1dPQc2c/view?usp=drivesdk",
    realesdata: "2024"
  },
  {
    id: 1118232,
    title: "Saba-Nayagan-2023",
    title1: "Saba-Nayagan",
    overview:
      "Arrested for nuisance, Saba spins breakup tales to melt a sympathetic cop, hoping to charm his way out.",
    poster_path: "https://www.themoviedb.org/t/p/original/uYIw5Jt4xkcjObDwJqcgeLFP042.jpg",
    background_path: "https://www.themoviedb.org/t/p/original/xf0Dg2NX6rnAfOPveP8Kp2v14G6.jpg",
    download_link: "https://drive.google.com/file/d/12DsJte2bRVESc40T1-mV1FmkZNxu3OnL/view?usp=drivesdk",
    realesdata: "2023"
  },
  {
    id: 1213076,
    title: "Fight-Club-2023",
    title1: "Fight-Club",
    overview:
      "An aspiring footballer's life is upended when his mentor is murdered. Entangled in revenge and power games, he tries to seize control of his destiny in time.",
    poster_path: "https://www.themoviedb.org/t/p/original/1h6t9nCYVWOLRskB35K51payMok.jpg",
    background_path: "https://www.themoviedb.org/t/p/original/nlDmuxSjJS1Fi2FgVToPm9ytm5S.jpg",
    download_link: "https://drive.google.com/file/d/11gEUcM7UgcxW6lHn2uToRY31JUdwtVfC/view?usp=drivesdk",
    realesdata: "2023"
  },
  {
    id: 1174777,
    title: "Parking-2023",
    title1: "Parking",
    overview:
      "A parking spot frays the relationship between two neighbors of different generations and worldviews.",
    poster_path: "https://www.themoviedb.org/t/p/original/glgCXwJMd9IHSLBocsr8N1te3Sf.jpg",
    background_path: "https://www.themoviedb.org/t/p/original/rIOjUd2fUazYTcnEfGLgSjG8i4m.jpg",
    download_link: "https://drive.google.com/file/d/11gNzculKcX37sbe978TUCxr0WBxIx7Mz/view?usp=drivesdk",
    realesdata: "2023"
  },
  {
    id: 949229,
    title: "Leo-2023",
    title1: "Leo",
    overview:
      "Things start to take an awry turn for a mild-mannered cafe owner, who gets caught in the crosshairs of a drug cartel.",
    poster_path: "https://www.themoviedb.org/t/p/original/pljRizZMI0RZ9dJEdSwqCRfYtr.jpg",
    background_path: "https://www.themoviedb.org/t/p/original/jpuhJikambSdHYf18OE6706yJkc.jpg",
    download_link: "https://drive.google.com/file/d/118piJq67i98QQntoNdUpSrzXZ-4sRPAW/view?usp=drivesdk",
    realesdata: "2023"
  },
  {
    id: 743563,
    title: "Vikram-2022",
    title1: "Vikram",
    overview:
      "A special agent investigates a murder committed by a masked group of serial killers. However, a tangled maze of clues soon leads him to the drug kingpin of Chennai.",
    poster_path: "https://www.themoviedb.org/t/p/original/774UV1aCURb4s4JfEFg3IEMu5Zj.jpg",
    background_path: "https://www.themoviedb.org/t/p/original/1GGc5XXaFgNBoJnOEBJCnXSMwhx.jpg",
    download_link: "https://drive.google.com/file/d/11YywkDAwIiaZpA4_e_8bkK95P5VogLb8/view?usp=drivesdk",
    realesdata: "2022"
  },
  {
    id: 922087,
    title: "Mark-Antony-2023",
    title1: "Mark-Antony",
    overview:
      "Mark, the son of a former gangster, comes across a mobile phone that can time-travel. Using the phone, Mark acquires the ability to save his estranged mother from a grim fate but with dangerous consequences.",
    poster_path: "https://www.themoviedb.org/t/p/original/3xz1lfMrFlNtZ4H8DQKvYog8ilR.jpg",
    background_path: "https://www.themoviedb.org/t/p/original/q8cXWpngGo5rYz2lkGJc5GiKcmL.jpg",
    download_link: "https://drive.google.com/file/d/12RcMIOMeERl-JFMI370bR3rd6R8zD5FU/view?usp=drivesdk",
    realesdata: "2023"
  },
  {
    id: 815051,
    title: "Maanadu-2021",
    title1: "Maanadu",
    overview:
      "A political leader and a bureaucrat are caught in a time loop, repeating the same day over and over. They must find a way to break free from this loop and alter the course of their lives.",
    poster_path: "https://www.themoviedb.org/t/p/original/mtvElEpi4uHYNz01DWfH7huMoBt.jpg",
    background_path: "https://www.themoviedb.org/t/p/original/yI7U3UpIlxPh8wFL67qHszt2oOX.jpg",
    download_link: "https://drive.google.com/file/d/1HcmEy2_MrS8J8rhjURK3OeQxkSx5kpP2/view?usp=drivesdk",
    realesdata: "2021"
  },
  {
    id: 1021776,
    title: "Maamannan-2023",
    title1: "Maamannan",
    overview:
      "A politician who faces trouble from his adversaries and their allies, struggles to maintain his position of power while dealing with family complications.",
    poster_path: "https://www.themoviedb.org/t/p/original/gVdylk4Jct5dPGRU7dAkEJ87d3d.jpg",
    background_path: "https://www.themoviedb.org/t/p/original/fnLeSO8bbUqvF4oqQapF4bqS7n7.jpg",
    download_link: "https://drive.google.com/file/d/1hbwAxskEX1vZzH6n0Z4tdo1-ldPLMBby/view?usp=drivesdk",
    realesdata: "2023"
  },
  {
    id: 738908,
    title: "Nanevaruvean-2023",
    title1: "Nanevaruvean",
    overview:
      "After a medical professional experiences a series of tragic events, he becomes entangled in a web of conspiracy and deception that threatens to unravel his sanity.",
    poster_path: "https://www.themoviedb.org/t/p/original/kZJ5n6zMEhuK13nczkB2pTeYtk3.jpg",
    background_path: "https://www.themoviedb.org/t/p/original/z9gUo82n0M1P4xtH8JnbCqYheZQ.jpg",
    download_link: "https://drive.google.com/file/d/1_RmH2X4QStWlMQ5Adh9fxsDNUxEkLae1/view?usp=drivesdk",
    realesdata: "2023"
  },
  {
    id: 904581,
    title: "Sardar-2022",
    title1: "Sardar",
    overview:
      "A man who tries to live a normal life discovers that he is the son of an infamous underworld don. He must come to terms with his past while dealing with his current life’s challenges.",
    poster_path: "https://www.themoviedb.org/t/p/original/4RUfB37G3sQJe4aZmX8X0iwFvNF.jpg",
    background_path: "https://www.themoviedb.org/t/p/original/22TfOgpZhzU0raEjWz9AiXe56NY.jpg",
    download_link: "https://drive.google.com/file/d/1vfwz0rz5Ck0jzVfU7kYTrMbEDmbxQeZi/view?usp=drivesdk",
    realesdata: "2022"
  }
];

  

export const vijayhits = [
  {
      id: 626392,
      title: "Master-2021",
      title1: "Master",
      overview:
          "An alcoholic professor is enrolled to teach at a juvenile facility, unbeknownst to him. He soon clashes with a ruthless gangster, who uses the children as scapegoats for his crimes.",
      poster_path: 'https://media.themoviedb.org/t/p/w440_and_h660_face/wjbOlovDadOdPKkSAMohLCjbIsc.jpg',
      background_path: "https://www.themoviedb.org/t/p/original/x1I63Mm5KU2DXjL4HdBy6JaGTms.jpg",
      download_link: "",
      realesdata: "2021"
  },
  {
      id: 800383,
      title: "Beast-2022",
      title1: "Beast",
      overview:
          "Former RAW agent Veera must face his fears when a terrorist organisation holds him hostage in a mall along with other visitors and demands the release of their leader, who was captured by Veera.",
      poster_path: 'https://media.themoviedb.org/t/p/w440_and_h660_face/yE8bsjy9hvuSY5JdtFlJGXyaTeH.jpg',
      background_path: "https://www.themoviedb.org/t/p/original/edwOyfLrZq9p16P4AAYcM8MzhJx.jpg",
      download_link: "https://drive.google.com/file/d/134kz8gYBPksdC5AmFnXyhmT_dxOMm8I_/view?usp=drivesdk",
      realesdata: "2022"
  },
  {
      id: 835017,
      title: "Varisu-2023",
      title1: "Varisu",
      overview:
          "The story revolves around the prodigal son of a business tycoon who agrees to take over the business, but must prove himself to be a worthy successor.",
      poster_path: 'https://media.themoviedb.org/t/p/w440_and_h660_face/iMUIsV6u0pPqfv1j8tGxbJw40sw.jpg',
      background_path: "https://www.themoviedb.org/t/p/original/m8ynNk1Ju112Xq6ouaSMXwF13bu.jpg",
      download_link: "https://drive.google.com/file/d/139iksBu_54wMH4QbpRaTeyIkLyEBq8EH/view?usp=drivesdk",
      realesdata: "2023"
  },
  {
      id: 563309,
      title: "Bigil-2019",
      title1: "Bigil",
      overview:
          "Years after a tragedy compelled Michael 'Bigil' Rayappan to give up a promising career in football, destiny presents him with an opportunity to pursue his passion for the sport as the coach of the women's team.",
      poster_path: 'https://media.themoviedb.org/t/p/w440_and_h660_face/aaZX7jvKGgTK9VoZpo5DAPL6lET.jpg',
      background_path: "https://www.themoviedb.org/t/p/original/cc14e095w0ri6ppoLxSEl7iUqHs.jpg",
      download_link: "https://drive.google.com/file/d/134kz8gYBPksdC5AmFnXyhmT_dxOMm8I_/view?usp=drivesdk",
      realesdata: "2019"
  },
  {
      id: 504231,
      title: "Sarkar-2018",
      title1: "Sarkar",
      overview:
          "NRI corporate Sundar Ramasamy comes to India to vote, only to learn that his vote has already been cast. While he reclaims his right legally, it also sets in motion a chain of events that eventually lead to him entering the political fray, trying to change the system.",
      poster_path: 'https://media.themoviedb.org/t/p/w188_and_h282_bestv2/6uF4MtjUuee9CrlzMr4u9bzEz7P.jpg',
      background_path: "https://www.themoviedb.org/t/p/original/v9CTg2i5d5oLMRIHAOOgFkxcJou.jpg",
      download_link: "https://drive.google.com/file/d/134kz8gYBPksdC5AmFnXyhmT_dxOMm8I_/view?usp=drivesdk",
      realesdata: "2018"
  },
  {
      id: 456287,
      title: "Mersal-2017",
      title1: "Mersal",
      overview:
          "A few individuals in the medical profession are murdered or kidnapped, and the cop investigating the case suspects a doctor and arrests him. But is he the one who is behind these crimes? And why are they being committed?",
      poster_path: 'https://media.themoviedb.org/t/p/w220_and_h330_face/65Q1wpRPZ5E3UVUv2V5JBbJQCcj.jpg',
      background_path: "https://www.themoviedb.org/t/p/original/rx9kyO6YJLpXfFrU934C6i4yYMj.jpg",
      download_link: "https://drive.google.com/file/d/134kz8gYBPksdC5AmFnXyhmT_dxOMm8I_/view?usp=drivesdk",
      realesdata: "2017"
  },
  {
      id: 370076,
      title: "Theri-2016",
      title1: "Theri",
      overview:
          "When an honest cop, Vijay Kumar's family is ruthlessly slaughtered by a politician and his aides, he decides to change his identity and commits his life to bring his daughter up in a serene atmosphere. But some freak events end up compromising his identity and what he does to save his daughter and avenge the death of his family unfurls as Theri.",
      poster_path: 'https://media.themoviedb.org/t/p/w220_and_h330_face/f6BhexotEqO3GejXa3FopBNGL6M.jpg',
      background_path: "https://www.themoviedb.org/t/p/original/yzoyr6nuB3ChA38MSHjYKlMpU6W.jpg",
      download_link: "https://drive.google.com/file/d/134kz8gYBPksdC5AmFnXyhmT_dxOMm8I_/view?usp=drivesdk",
      realesdata: "2016"
  },
  {
      id: 263475,
      title: "Kaththi-2014",
      title1: "Kaththi",
      overview:
          "Kathiresan aka Kaththi, a criminal, escapes from the Kolkata prison and comes to Chennai, where he comes across his doppelganger Jeevanandham, fighting for his life after being shot at by unknown men. Kathir decides to pass off as Jeeva and make away with a lump sum amount but once he realizes who Jeeva really is, Kathir turns a crusader.",
      poster_path: 'https://media.themoviedb.org/t/p/w220_and_h330_face/3Zp3VCEzDJGHwvMT7pXkPj2vuYm.jpg',
      background_path: "https://www.themoviedb.org/t/p/original/iOnnXN8XPMWrh7flteI1mCftYBZ.jpg",
      download_link: "https://drive.google.com/file/d/134kz8gYBPksdC5AmFnXyhmT_dxOMm8I_/view?usp=drivesdk",
      realesdata: "2014"
  },
  {
      id: 246087,
      title: "Jilla-2014",
      title1: "Jilla",
      overview:
          "A cop decides to take on his godfather, who raised him from childhood, and make him realize the error of his ways.",
      poster_path: 'https://media.themoviedb.org/t/p/w188_and_h282_bestv2/kUBsvLoyzgxTqTvuiE0PmJHpsRU.jpg',
      background_path: "https://www.themoviedb.org/t/p/original/n06GR0Jmd1gwglNVKdyJIphQG3d.jpg",
      download_link: "https://drive.google.com/file/d/134kz8gYBPksdC5AmFnXyhmT_dxOMm8I_/view?usp=drivesdk",
      realesdata: "2014"
  },
  {
      id: 143010,
      title: "Thuppakki-2012",
      title1: "Thuppakki",
      overview:
          "An army captain is on a mission to track down and destroy a terrorist gang and deactivate the sleeper cells under its command.",
      poster_path: 'https://media.themoviedb.org/t/p/w220_and_h330_face/8Y4LlML98PvZWFnOtGFzY1wHwwi.jpg',
      background_path: "https://www.themoviedb.org/t/p/original/lwzqTj8WyaMM0TQdz7bdpDAfZwc.jpg",
      download_link: "https://drive.google.com/file/d/134kz8gYBPksdC5AmFnXyhmT_dxOMm8I_/view?usp=drivesdk",
      realesdata: "2012"
  }
];

    


export const main_slider = [
  {
    id: 781732,
    title: "Animal",
    title1: "Animal",
    overview:
      "The son of a wealthy, powerful industrialist returns to India and undergoes a remarkable transformation as he becomes consumed by a quest for vengeance against those threatening his father's life.",
    poster_path: 'https://image.tmdb.org/t/p/original/q14R8XgOKZx2ytwMg7PPqBmGPv0.jpg',
    backdrop_path: "https://www.themoviedb.org/t/p/original/nBIHiMCLjdDX2GXbbPBRWafFJNn.jpg",
    download_link: "https://tamilvip.app/link/?jjb4lao2shk3",
  },
  {
    id: 1072790,
    title: "Anyone But You",
    overview:
      "After an amazing first date, Bea and Ben’s fiery attraction turns ice cold — until they find themselves unexpectedly reunited at a destination wedding in Australia. So they do what any two mature adults would do: pretend to be a couple.",
    poster_path: "https://media.themoviedb.org/t/p/w440_and_h660_face/4SOtfIlRuEGa10YiopG9liQSecI.jpg",
    backdrop_path: "https://www.themoviedb.org/t/p/original/nTPFkLUARmo1bYHfkfdNpRKgEOs.jpg",
    download_link: "https://drive.google.com/file/d/11A_TEDjo9cnR_gN263yP_oK0gmFf8CHU/view?usp=drivesdk",
  },
  {
    id: 962074,
    title: "Captain-Miller",
    overview:
      "A renegade Captain and his unconventional outlaws execute daring heists in the 1930s and 1940s. Miller must decide whether to continue running or confront the challenges head-on.",
    poster_path: "https://media.themoviedb.org/t/p/w188_and_h282_bestv2/gq5OlT5ImxsNpkirpgf4rYngd3M.jpg",
    backdrop_path: "https://www.themoviedb.org/t/p/original/u3lWiLi0e0W3aU822QdcxQIGBES.jpg",
    download_link: "https://drive.google.com/file/d/1-WJ5aRgv30NinWCYOj8EQmMEC-ahTjjD/view?usp=drivesdk",
  },
  {
    id: 1096342,
    title: "Beautiful Wedding",
    overview:
      "In the aftermath of Beautiful Disaster, Abby and Travis wake after a crazy night in Vegas as accidental newlyweds! With the mob on their heels, they flee to Mexico for a wild, weird honeymoon—but are they in for another disaster?",
    poster_path: "https://media.themoviedb.org/t/p/w440_and_h660_face/cmeYlRD628KrijyY7thzwlV2KPT.jpg",
    backdrop_path: "https://www.themoviedb.org/t/p/original/42LVYCw3c36fT0jGbjYpO4G2zi6.jpg",
    download_link: "https://drive.google.com/file/d/1198ztKFCOFbwFF4ZV__kr4YSl1dPQc2c/view?usp=drivesdk",
  },
  {
    id: 1118232,
    title: "Saba-Nayagan",
    overview:
      "Arrested for nuisance, Saba spins breakup tales to melt a sympathetic cop, hoping to charm his way out.",
    poster_path: "https://media.themoviedb.org/t/p/w220_and_h330_face/uYIw5Jt4xkcjObDwJqcgeLFP042.jpg",
    backdrop_path: "https://www.themoviedb.org/t/p/original/xf0Dg2NX6rnAfOPveP8Kp2v14G6.jpg",
    download_link: "https://drive.google.com/file/d/12DsJte2bRVESc40T1-mV1FmkZNxu3OnL/view?usp=drivesdk",
  },
];



export const ajith_hits = [
  {
    id: 904557,
    title1: "Thunivu",
    overview:
      "A gang goes to rob a bank only to find that there's already a criminal mastermind holding it for ransom, but his identities and motives behind the heist remain mysterious. As they plan to collect the bounty and disappear without a trace, their crimes and their past slowly catch up with them.",
    poster_path: 'https://media.themoviedb.org/t/p/w220_and_h330_face/5Cdi9MFxEGsfphvDxV4TyY4znP6.jpg',
    background_path: "https://www.themoviedb.org/t/p/original/woLEI1MC1Ya4eHrFveUXd60hVU.jpg",
    download_link: "",
  },
  {
    id: 640484,
    title1: "Valimai",
    overview:
      "A gang goes to rob a bank only to find that there's already a criminal mastermind holding it for ransom, but his identities and motives behind the heist remain mysterious. As they plan to collect the bounty and disappear without a trace, their crimes and their past slowly catch up with them.",
    poster_path: 'https://media.themoviedb.org/t/p/w220_and_h330_face/xa5USFw4I2SQPlD3wZ9kRn9shtB.jpg',
    background_path: "https://www.themoviedb.org/t/p/original/woLEI1MC1Ya4eHrFveUXd60hVU.jpg",
    download_link: "",
  },
  {
    id: 586291,
    title1: "Nerkonda Paarvai",
    overview:
      "When three young women are harassed and implicated in a crime they didn't commit, a bipolar lawyer sets out to help them clear their names and prove to everyone that a woman's consent matters.",
    poster_path: 'https://media.themoviedb.org/t/p/w220_and_h330_face/8Ttg8jI3rOG6RSAj195MwdXlnc1.jpg',
    background_path: "https://www.themoviedb.org/t/p/original/kgqZkSw8pH8AX365k15kULHs0fk.jpg",
    download_link: "",
  },
  {
    id: 503356,
    title1: "Viswasam",
    overview:
      "A village ruffian, who settles disputes in his native, takes on a big shot when he tries to sort out the rough patch in his marriage after several years.",
    poster_path: 'https://media.themoviedb.org/t/p/w220_and_h330_face/9XXABbZY62OWkkjUDPVysMTs7fc.jpg',
    background_path: "https://www.themoviedb.org/t/p/original/oq0YL751gFY1lQBZM06Vb18Cav7.jpg",
    download_link: "",
  },
  {
    id: 362150,
    title1: "Vedalam",
    overview:
      "Ganesh, a cab driver and a doting brother to his sister Tamizh, is hunting down three notorious criminals in Kolkata. Who is he actually and what's his motive?",
    poster_path: 'https://media.themoviedb.org/t/p/w188_and_h282_bestv2/cily2czYaIla7RoPQc8L1KO948e.jpg',
    background_path: "https://www.themoviedb.org/t/p/original/pVvJJgqnvj6JjHpCyzmIExGurkk.jpg",
    download_link: "",
  },
  {
    id: 300983,
    title1: "Yennai Arindhaal",
    overview:
      "Sathyadev, who is on a career break, is forced to put on his cop shoes once again to save Thenmozhi, a young woman who is the target of a criminal organ harvesting group, led by a ghost from his past.",
    poster_path: 'https://media.themoviedb.org/t/p/w220_and_h330_face/9IY8JqjLE63AESmzDGKmfhlgvGZ.jpg',
    background_path: "https://www.themoviedb.org/t/p/original/cDuWMH2lXenwj5MfCtM9EqHONwK.jpg",
    download_link: "",
  },
  {
    id: 247504,
    title1: "Veeram",
    overview:
      "A straightforward man, who uses violence to settle disputes, decides to mend his ways for the sake of his lover, but when he learns that her family is in danger, he decides to save them at all costs.",
    poster_path: 'https://media.themoviedb.org/t/p/w220_and_h330_face/nN8R6j4KhKh5W7AQmtTcIgeGSK7.jpg',
    background_path: "https://www.themoviedb.org/t/p/original/4wTcPRyHlWdpCpIUmfPDrMvMBZ6.jpg",
    download_link: "",
  },
  {
    id: 188540,
    title1: "Arrambam",
    overview:
      "A man, along with a computer hacker, sets off on a mission to uncover a government scandal. As the dark secrets begin to tumble out.",
    poster_path: 'https://media.themoviedb.org/t/p/w220_and_h330_face/7rGkg7cGm93ql4vDJzS38P5cf2E.jpg',
    background_path: "https://www.themoviedb.org/t/p/original/xlGnLRuV8XFDZXGdNN3tQblOWlf.jpg",
    download_link: "",
  }
];
