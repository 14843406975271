export const showflixapi = [
        {
            "objectId": "ymgyXTZQsy",
            "movieName": "Black (2024)",
            "rating": " 6.5",
            "storyline": "Vasanth and Aaranya, a couple move into a serene row house, eager for peace and privacy, but a violent storm leaves them trapped inside, unsettling supernatural disturbances threaten to unravel their marriage. Vasanth must uncover the truth behind these supernatural occurrences before everything falls apart.",
            "poster": "https://www.themoviedb.org/t/p/original/wMx8Msg2OmAb7pgxpmSCAtWJ8a7.jpg",
            "backdrop": "https://www.themoviedb.org/t/p/original/9JFimiB1nbR3HbBKRFV9F5XlaXQ.jpg",
            "category": "Tamil",
            "streamlink": "https://embedsb.com/play/.html",
            "language": "ta",
            "hdlink": "https://showflix.shop/archives/7969",
            "sharedisk": "",
            "streamhide": "",
            "streamwish": "jim8rowjz6cd",
            "filelions": "15jk69irzvyr",
            "streamruby": "9hpsfnqh009g",
            "uploadever": "xna2v597xmzz",
            "HubCloud": "https://showflix.shop/archives/7972",
            "upnShare": "3f6pd",
            "createdAt": "2024-11-01T11:43:13.227Z",
            "updatedAt": "2024-11-01T11:43:13.227Z"
        },
        {
            "objectId": "iS0GLIywGH",
            "movieName": "Lubber Pandhu (2024)",
            "rating": " 7.8",
            "storyline": "Middle aged local cricketer Poomalai finds a new rival in the form of a younger sports person named Anbu. Their rivalry in cricket gets complicated when Anbu falls in love with Poomalai's daughter Durga.",
            "poster": "https://www.themoviedb.org/t/p/original/4QAXWkF3pf8lb3JD2fTeMWdmpR1.jpg",
            "backdrop": "https://www.themoviedb.org/t/p/original/8BemLDPKS52ZKgqzwD21W5BEZAY.jpg",
            "category": "Tamil Telugu Hindi Malayalam",
            "streamlink": "https://embedsb.com/play/.html",
            "language": "ta",
            "hdlink": "https://showflix.shop/archives/7951",
            "sharedisk": "",
            "streamhide": "",
            "streamwish": "n6vzp5xkz4yq",
            "filelions": "49ajx9zpbqqa",
            "streamruby": "4anads0x2s02",
            "uploadever": "g9cwa7lmq9cg",
            "HubCloud": "https://showflix.shop/archives/7953",
            "upnShare": "xpseg",
            "createdAt": "2024-10-31T02:51:20.889Z",
            "updatedAt": "2024-10-31T02:51:20.889Z"
        },
        {
            "objectId": "NOJKA21gFz",
            "movieName": "Vettaiyan (2024)",
            "rating": " 5.8",
            "storyline": "A sought-after Supercop gets caught in a series of unexpected events when he encounters a suspect who is accused of being involved in a murder case.",
            "poster": "https://www.themoviedb.org/t/p/original/pFP2BhJxZCV6FOnuu1qw1G44Urp.jpg",
            "backdrop": "https://www.themoviedb.org/t/p/original/ryeuX0wMWvw5LoarJ583FVP6p0K.jpg",
            "category": "Tamil",
            "streamlink": "https://embedsb.com/play/.html",
            "language": "ta",
            "hdlink": "https://showflix.shop/archives/7913",
            "sharedisk": "",
            "streamhide": "",
            "streamwish": "lisc8mw41vah",
            "filelions": "4ffmh2j47xlm",
            "streamruby": "vvzv24q0n8by",
            "uploadever": "",
            "HubCloud": "https://showflix.shop/archives/7893",
            "upnShare": "lnovo",
            "createdAt": "2024-10-24T17:08:12.057Z",
            "updatedAt": "2024-10-25T05:58:45.722Z"
        },
        {
            "objectId": "5JXXThBccD",
            "movieName": "Meiyazhagan (2024)",
            "rating": " 8",
            "storyline": "Twenty-two years after losing his home, Arulmozhi Varman returns to his native Thanjavur to attend his cousin's wedding. Amidst the celebrations, Arul is reintroduced to an upbeat man whom he cannot recall. With the help of the unknown man, Arul reconnects with his past.",
            "poster": "https://www.themoviedb.org/t/p/original/ngDEH7YqVaMCAD4LpNxRl6ScJnw.jpg",
            "backdrop": "https://www.themoviedb.org/t/p/original/9v5Q5OOR9e83KBlK6SPQEcbm6Iw.jpg",
            "category": "Tamil",
            "streamlink": "https://embedsb.com/play/.html",
            "language": "ta",
            "hdlink": "https://showflix.shop/archives/7895",
            "sharedisk": "",
            "streamhide": "",
            "streamwish": "8128pch3ssqm",
            "filelions": "d4r04a9xc1pi",
            "streamruby": "vvf12f3ox73e",
            "uploadever": "d5rw4ohcl5cm",
            "HubCloud": "https://showflix.shop/archives/7906",
            "upnShare": "d3cs5",
            "createdAt": "2024-10-25T04:59:44.463Z",
            "updatedAt": "2024-10-25T04:59:44.463Z"
        },
        {
            "objectId": "HgJrnzVVKG",
            "movieName": "Kadaisi Ulaga Por (2024)",
            "rating": " 7.5",
            "storyline": "In a dystopian near-future, political factions within India clash when international forces block trade to the country, leading to civil unrest.",
            "poster": "https://www.themoviedb.org/t/p/original/tJOfISYey4xqo4DtcVVf9x1AwxW.jpg",
            "backdrop": "https://www.themoviedb.org/t/p/original/rs6nivYQczhtEXLFqx8U2puMBsU.jpg",
            "category": "Tamil",
            "streamlink": "https://embedsb.com/play/.html",
            "language": "ta",
            "hdlink": "https://showflix.shop/archives/7885",
            "sharedisk": "",
            "streamhide": "",
            "streamwish": "bnmqpicb941w",
            "filelions": "ytzpki977s1v",
            "streamruby": "b0nrwsnp99i6",
            "uploadever": "re9zo6smtu6u",
            "HubCloud": "https://showflix.shop/archives/7887",
            "upnShare": "9nllf",
            "createdAt": "2024-10-24T02:21:06.556Z",
            "updatedAt": "2024-10-24T02:21:06.556Z"
        },
        {
            "objectId": "tZDYFgLbrK",
            "movieName": "Maharaja (2024)",
            "rating": " 8.4",
            "storyline": "In a quiet neighborhood, Maharaja, a respected middle-aged barber, lives with his daughter Jothi and Lakshmi. When Maharaja reports to the police that masked intruders attacked him and robbed Lakshmi, the authorities reject this claim, doubting the authenticity of her loss. Despite his repeated attempts to seek help, he was willing to go all the way, even investigating it himself before his daughter returned. As suspicions grow and the Maharaja's sanity is questioned, the mystery deepens.",
            "poster": "https://www.themoviedb.org/t/p/original/iakDhBTwNM0MwfI8y0fuWUZej51.jpg",
            "backdrop": "https://www.themoviedb.org/t/p/original/o6G8llbhWHnhKzNPSSvIbic5NWe.jpg",
            "category": "Tamil Telugu Hindi Kannada Malayalam",
            "streamlink": "https://embedsb.com/play/.html",
            "language": "ta",
            "hdlink": "https://showflix.shop/archives/7756",
            "sharedisk": "",
            "streamhide": "",
            "streamwish": "racti6xm6bkd",
            "filelions": "3q1e1mj8i3ed",
            "streamruby": "gjh9kek9o2t8",
            "uploadever": "",
            "HubCloud": "https://showflix.shop/archives/7758",
            "createdAt": "2024-07-12T07:26:29.127Z",
            "updatedAt": "2024-07-12T07:26:29.127Z"
        },
        {
            "objectId": "bH5XPCZHMg",
            "movieName": "Aranmanai 4 (2024)",
            "rating": " 0",
            "storyline": "After his sister's suspicious death, a man decides to discover the hidden truth, setting off a chasm for chaos and terror.",
            "poster": "https://www.themoviedb.org/t/p/original/k4d4RmvBVtSF8SGgTGAqPZlsOg1.jpg",
            "backdrop": "https://www.themoviedb.org/t/p/original/BqPHa5sz4wTzzRZkyLqXFi0E1x.jpg",
            "category": "Tamil Malayalam Kannada",
            "streamlink": "https://embedsb.com/play/.html",
            "language": "ta",
            "hdlink": "https://showflix.shop/archives/7631",
            "sharedisk": "",
            "streamhide": "",
            "streamwish": "vc1l9pc25jfb",
            "filelions": "mzo4z26pqn00",
            "streamruby": "lunh3skyxljw",
            "uploadever": "lia53di0rqor",
            "createdAt": "2024-06-21T08:34:51.470Z",
            "updatedAt": "2024-06-21T08:34:51.470Z"
        }
    ]
