import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const Headeritems = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [targetPosition, setTargetPosition] = useState({ x: 0, y: 0 });
    const [scroll , setScroll] = useState(true)

    const header_list = [
        {
          title: 'Movies',
          link: '/'
        },
        {
          title: 'Tv-series',
          link: '/tv-series'
        },
        {
          title: 'Search',
          link: '/search-catagory'
        },
        {
          title: 'Contact-us',
          link: '/contact'
        },
        {
            title : 'Developer-Profile ',
            link : '/developer-profile'
        }
    ];

    useEffect(() => {
        const handlemousemove = (e) => {
            setPosition({
                x: e.clientX,
                y: e.clientY
            });
        };

        window.addEventListener('mousemove', handlemousemove);

        return () => window.removeEventListener('mousemove', handlemousemove);
    }, []);

    useEffect(() => {
        const navitems = document.querySelectorAll('.navitems');
        navitems.forEach((i) => {
            const rect = i.getBoundingClientRect();

            if (
                position.x >= rect.left &&
                position.x <= rect.right &&
                position.y >= rect.top &&
                position.y <= rect.bottom
            ) {
                setTargetPosition({
                    x: rect.left - 528,
                    y: rect.top +2,
                    width: rect.width ,
                    height: rect.height-4,
                });
            }
        });
    }, [position]);

    const mouseeffect = {
        position: 'fixed',
        width: `${targetPosition.width}px`,
        height: `${targetPosition.height}px`,
        backgroundColor: '#313030',
        borderRadius: '4px',
        transform: `translate(${targetPosition.x}px, ${targetPosition.y}px)`,
        transition: 'transform 0.3s ease, width 0.3s ease, height 0.3s ease', // Smooth transition
        pointerEvents: 'none',
        zIndex: -1, 
    };
  
   
    const handlescroll = () => {
       
        setScroll(window.scrollY === 0)
       
    }
   useEffect(() => {

  
    window.addEventListener('scroll' , handlescroll)

    return () => window.removeEventListener('scroll' , handlescroll)
   },[])
    return (
        <>
           {scroll  && <div className="hidden sm:flex ml-[400px] fixed  mt-[-33px] ">
                <div style={mouseeffect} ></div>

                {header_list.map((item, index) => (
                    <Link to={item.link} key={index}>
                        <h1 className='p-2 text-gray-400 m-4 navitems relative'>
                            {item.title}
                        </h1>
                    </Link>
                ))}
            </div>}
        </>
    );
};


